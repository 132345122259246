<template>
  <div>
    <Heard />
    <div class="banner-box container-fluid">
      <img class="container-fluid" src="@/assets/image/stubannner.png" alt="" />
    </div>

    <div class="container coursebox">
      <!-- <div class="title">鏍稿績璇剧▼</div> -->
      <div class="courseitem">
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          @click="clickitem(item)"
        >
          <img :src="item.image" alt="" />
          <p class="smtit">{{ item.title }}</p>
          <p class="desc">{{ item.remark }}</p>
        </div>
      </div>
    </div>
    <div class="content container-fluid">
      <img class="container-fluid" src="@/assets/image/temcont.jpg" alt="" />
    </div>
    <Footer />
  </div>
</template>

<script>
import Heard from "@/components/Heard.vue";
import Footer from "@/components/Footer.vue";
import { api_v1queryProductCList } from "@/apis/apis.js";

export default {
  components: {
    Heard,
    Footer,
  },

  created() {
    this.getlist();
  },
  data() {
    return {
      bannerlist: [require("@/assets/image/stubannner.png")],
      list: [],
    };
  },
  // watchQuery: ["id"],
  methods: {
    getlist() {
      api_v1queryProductCList({
        tagId: 29,
      }).then((res) => {
        this.list = res.data.data.dataList;
      });
    },
    clickitem(item) {
      console.log(item);
      this.$router.push(`template/${item.id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.container-fluid {
  min-width: 1200px;
  /* 鐗堝績 */
}
.container {
  width: 1200px;
  margin: 0 auto;
}
.banner-box {
  display: flex;
  justify-content: center;

  img {
    // -width: 100%;
    max-height: 400px;
  }
}
.coursebox {
  // margin-top: 55px;
  // .title {
  //   margin-bottom: 30px;
  //   width: 114px;
  //   font-size: 24px;
  //   color: #000000;
  //   text-align: center;
  //   border-left: 8px solid #5f9eff;
  // }
  .courseitem {
    display: flex;
    flex-wrap: wrap;
    .item:hover {
      background-color: #fff;
      box-shadow: 2px 2px 4px #ccc;
    }
    .item {
      margin-bottom: 32px;
      margin-right: 24px;

      img {
        width: 360px;
        height: 210px;
        border-radius: 10px;
      }
      .smtit {
        font-size: 18px;
        color: #000000;
        margin: 20px 0 16px;
      }
      .desc {
        font-size: 14px;
        color: #969696;
      }
    }
  }
}
.content {
  // height: 1200px;
  // background: url(~@/assets/image/temcont.jpg) no-repeat center;
  // background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    -height: 400px;
  }
}
</style>